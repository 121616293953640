if ( typeof django !== 'undefined') {
	(function($) {
	    $(document).ready(function($) {
	        $(".object-tools").append('<li><a id="aumentar_precios" href="javascript:;" class="addlink">Aumentar 20% productos predefinidos</a></li>');
	   	
		    $('#aumentar_precios').click(function() {
		    	var r = confirm("Está seguro que desea aumentar los precios?");
				if (r == true) {
				    window.location.href = "/producto/aumentar-precios-predefinidos/";
				};
		    });
	     });
	})(django.jQuery);
}
