$('body').on('click', '.eliminar_producto', function (){
    var producto_pk = $(this).data('pk');
    eliminar_producto(producto_pk);
});


function eliminar_producto(producto_pk) {
    $.ajax({
        url : "/regalos/eliminar-producto/",
        type : "POST",
        data: {'pk': producto_pk},
        success : function(response) {
            if (response == 'Removed') {
                window.location.href =  window.location.href.replace(window.location.search,'');
            }
        },
        error : function(err) {
            console.log(err);
            $.growl.error({ title: "", message: 'Ocurrió un error, intente nuevamente mas tarde.', size: "xlarge"});
        }
    });
}



function update_cart_badge() {
    $.ajax({
        url : "/regalos/get-cart-count/",
        type : "POST",
        success: function(response) {
            if (response.success) {
                $('.cart_count').text(response.cant);
            }
        },
        error: function(err) {
            $.growl.error({ title: "", message: 'Ocurrió un error, intente nuevamente mas tarde.', size: "xlarge"});
        }
    });
}

