$(function() {
	// Set active to navs
    $('.nav-pills a').filter(function() {
        return this.href == location.href;
    }).parent().addClass('active').siblings().removeClass('active');

    $('.cuenta_nav').filter(function() {
        return this.href == location.href;
    }).find('button').addClass('active').siblings().removeClass('active');

    $('.user-profile_sections').filter(function() {
        return this.href == location.href;
    }).addClass('active').siblings().removeClass('active');
});